
.paragraph::first-letter{
    text-transform: uppercase;
    color: red;
    font-size: 30px;
}

.firstCharacter::first-letter{
    text-transform: capitalize;
    color: red;
}

/* .nameTag{
} */

.priceTag{
    margin: 0 14rem 0 11rem;
}

/* .firstCharacter span {
    display: inline-block;
  }
.firstCharacter span::first-letter {
    text-transform: uppercase;
    color: red;
  } */